import { forwardRef } from 'react';
import type { StackProps } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { List as MuiList, Stack } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

type PpListWithSpacing = Pick<
  StackProps,
  'direction' | 'divider' | 'spacing' | 'sx' | 'useFlexGap'
> &
  PpWC & {
    disablePadding?: boolean;
  };

export const List = forwardRef<HTMLUListElement, PpListWithSpacing>(
  (
    { children, direction = 'column', disablePadding = false, divider, spacing, sx, useFlexGap },
    ref,
  ): React.ReactElement => (
    <Stack
      ref={ref}
      component={MuiList}
      direction={direction}
      disablePadding={disablePadding}
      divider={divider}
      spacing={spacing}
      sx={sx}
      useFlexGap={useFlexGap}
    >
      {children}
    </Stack>
  ),
);

List.displayName = List.name;
