export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function randomSleep(max = 500): Promise<void> {
  const randomTime = Math.floor(Math.random() * Math.floor(max));
  return sleep(randomTime);
}

export function trueKeysFromBooleanMap(map: Record<string, boolean>): string[] {
  const filtered = Object.entries(map).filter(([_, value]) => Boolean(value));

  return Array.from(filtered, (element) => element[0]);
}

export function isUndefinedOrNull(value: unknown): value is null | undefined {
  return value == null;
}

export function removeNullUndefinedFields<T extends object>(
  obj: T,
): { [K in keyof T]: NonNullable<T[K]> } {
  const result = {} as { [K in keyof T]: NonNullable<T[K]> };

  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    const value = obj[key] as T[keyof T] | null | undefined;
    if (value != null) {
      // Handles both null and undefined
      result[key] = value;
    }
  });

  return result;
}
export type TpInterval = ReturnType<typeof setInterval>;
export type TpTimeout = ReturnType<typeof setTimeout>;
export async function withTimeout<T>(promise: Promise<T> | T, ms: number): Promise<T> {
  let timer: TpTimeout | null = null;

  // This promise will reject after the given timeout
  const timeoutPromise = new Promise<T>((_, reject) => {
    timer = setTimeout(() => {
      reject(new Error('Promise timed out'));
    }, ms);
  });

  try {
    // Wait for either the input promise or the timeout promise to settle
    const result = await Promise.race([promise, timeoutPromise]);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clearTimeout(timer!);
    return result;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clearTimeout(timer!);
    throw error;
  }
}
