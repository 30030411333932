import type { ReactElement } from 'react';
import { Fragment } from 'react';
import { truncateString } from '@noah-labs/shared-util-vanilla';

export type PpTruncatedText = {
  prefix?: number;
  suffix?: number;
  text: string;
};

export function TruncatedText({ prefix, suffix, text }: PpTruncatedText): ReactElement {
  const truncatedText = truncateString(text, { prefix, suffix });

  return <Fragment>{truncatedText}</Fragment>;
}
