import type {
  AppConfigCoreCurrencies,
  AppConfigManualRamp,
  AppConfigNetworkFee,
} from '@noah-labs/shared-schema-gql';

export type TpConfig = {
  currencies: AppConfigCoreCurrencies;
  isProd: boolean;
  manualRamp: AppConfigManualRamp;
  networkFee: AppConfigNetworkFee;
};

let config: TpConfig | undefined;

export function setConfig(cfg: TpConfig): void {
  config = cfg;
}

export function getConfig(): TpConfig {
  if (!config) {
    throw new Error(
      'config for data-access-wallet is not set, please call `setConfig()` at the start of your application',
    );
  }
  return config;
}
