import { useEffect } from 'react';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { LocationDescriptor } from 'history';
import { LoadingPage } from '../utility/LoadingPage';

export function Redirect<T>({ to }: { to: LocationDescriptor<T> | string }): React.ReactElement {
  const { replace } = useRouter();

  useEffect(() => {
    replace(to);
  }, [replace, to]);

  return <LoadingPage />;
}
