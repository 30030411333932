//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgRadio } from '../icons/Radio';

export function RadioIcon(props: Omit<SvgIconProps, 'children' | 'component'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgRadio} data-qa="RadioIcon">
      <svg />
    </SvgIcon>
  );
}
