/* eslint-disable no-underscore-dangle */
import type { HDWallet } from './hdWallet';
import { bufferToBase64 } from './utils';

export type TpCryptoDocuments = {
  keyAlgorithm: 'Secp256k1';
  keyId: string;
  mnemonic: SafeWrapper<string>;
  pinkIterations: number;
  pinkKdf: 'Pbkdf2';
  pinkSalt: SafeWrapper<ArrayBuffer>;
  publicKey: SafeWrapper<ArrayBuffer>;
  secretDocument: SafeWrapper<ArrayBuffer>;
  secretValue: SafeWrapper<ArrayBuffer>;
  signature: SafeWrapper<ArrayBuffer>;
  supersedeSignature?: SafeWrapper<ArrayBuffer>;
  tag: SafeWrapper<ArrayBuffer>;
};

export type TpAppInput = {
  appInput: SafeWrapper<ArrayBuffer>;
  decryptionKey: SafeWrapper<CryptoKey>;
};

export type TpSupersede = {
  mnemonic: SafeWrapper<string>;
  prevTag: ArrayBuffer;
};

export type TpSetupWallet = {
  isProd: boolean;
  pin: SafePin;
  supersede?: TpSupersede;
};

export type TpSecretValueRequest = {
  decryptionKey: SafeWrapper<CryptoKey>;
  encryptedAppInput: string;
  keyId: string;
};

export type TpSignPayload = {
  payload: string;
  wallet: HDWallet;
};

export type Secret<T> = {
  readonly secret: T;
};

export type SafePin = SafeWrapper<string>;

type SafeWrapperValueBase64<T> = T extends ArrayBuffer | Uint8Array | string ? string : never;

/**
 * Encapsulates sensitive data to avoid accidental logging or leaking
 */
export class SafeWrapper<T extends ArrayBuffer | CryptoKey | number | string> {
  private readonly _value: T;

  constructor(value: T) {
    this._value = value;
  }

  get value(): Secret<T> {
    return {
      secret: this._value,
    };
  }

  toBase64(): SafeWrapperValueBase64<T> {
    if (typeof this._value === 'string') {
      return Buffer.from(this._value).toString('base64') as SafeWrapperValueBase64<T>;
    }
    if (this._value instanceof ArrayBuffer || this._value instanceof Uint8Array) {
      return bufferToBase64(this._value) as SafeWrapperValueBase64<T>;
    }

    throw new Error('Cannot convert to base64');
  }

  base64ToBuffer(): SafeWrapper<ArrayBuffer> | never {
    if (typeof this._value !== 'string') {
      throw new Error('Cannot convert to base64');
    }
    return new SafeWrapper(Buffer.from(this._value, 'base64'));
  }
}
