import { isUndefinedOrNull } from './utils';

export function parseTruthyEnv(envvar: boolean | number | string | null | undefined): boolean {
  const truthyValues = ['true', '1'];

  if (!isUndefinedOrNull(envvar) && truthyValues.includes(envvar.toString().toLowerCase())) {
    return true;
  }
  return false;
}

export const partialSnapshots = parseTruthyEnv(process.env.STORYBOOK_CHROMATIC_PARTIAL);
