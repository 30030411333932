//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgInfo } from '../icons/Info';

export function InfoIcon(props: Omit<SvgIconProps, 'children' | 'component'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgInfo} data-qa="InfoIcon">
      <svg />
    </SvgIcon>
  );
}
