import { useEffect } from 'react';
import { checkAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from './useAuth';

export function useQueryAuthGuard(): void {
  const auth = useAuth();
  const queryClient = useQueryClient();

  const isAuthenticated = checkAuthStatus({
    has: auth.authStatus,
    needs: ['authenticated'],
  });

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        enabled: isAuthenticated,
      },
    });
    // need to invalidate queries to 'enable' them again
    void queryClient.invalidateQueries();
  }, [isAuthenticated, queryClient]);
}
