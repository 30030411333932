import { Switch } from '@mui/material';
import {
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemStartContent,
} from '../lists/ListItem';
import { CircularSpinner } from '../utility/CircularSpinner';

export type PpMenuItemSwitch = {
  checked: boolean;
  dataQa?: string;
  disabled?: boolean;
  icon: React.ReactNode;
  isLoading: boolean;
  label: string;
  onClick: () => void;
};

export function MenuItemSwitch({
  checked,
  dataQa,
  disabled,
  icon: Icon,
  isLoading,
  label,
  onClick,
}: PpMenuItemSwitch): React.ReactElement {
  return (
    <ListItem data-qa={dataQa}>
      <ListItemButton disabled={disabled} onClick={onClick}>
        <ListItemCard withHoverEffect>
          <ListItemContent>
            <ListItemIcon>{Icon}</ListItemIcon>
            <ListItemStartContent>
              <ListItemPrimaryText>{label}</ListItemPrimaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              {isLoading ? (
                <CircularSpinner size={24} sx={{ display: 'flex' }} />
              ) : (
                <Switch aria-checked={checked} checked={checked} />
              )}
            </ListItemEndContent>
          </ListItemContent>
        </ListItemCard>
      </ListItemButton>
    </ListItem>
  );
}
