import type { TpAmount, TpFiatCurrency } from '@noah-labs/shared-currencies';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { TextOrSkeleton } from '../typography/TextOrSkeleton';
import { renderAmountAsParts } from './renderAmountAsParts';

type PpGetAmountAsParts = {
  amount: TpAmount;
  currency: TpFiatCurrency | undefined;
  locale?: string;
  roundDown?: boolean;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
};

function getAmountAsParts({
  amount,
  currency,
  locale,
  roundDown,
  signDisplay,
}: PpGetAmountAsParts): Intl.NumberFormatPart[] | null | undefined {
  // Number(null) === 0, hence check this first
  if (isUndefinedOrNull(amount)) {
    return amount;
  }

  const amountAsNumber = Number(amount);
  if (Number.isNaN(amountAsNumber)) {
    return [];
  }

  // Used to switch when the number will be formatted in the compact way (10M)
  const compactFormatAt = 10000000;
  const absoluteNumber = Math.abs(amountAsNumber);

  const options: Intl.NumberFormatOptions = {
    compactDisplay: 'short',
    currency: currency?.code,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    // only ever use $ / £, not USD$
    notation: 'standard',
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#rounding_modes
    roundingMode: roundDown ? 'trunc' : 'halfExpand',
    signDisplay,
    style: currency?.code ? 'currency' : 'decimal',
  };

  if (absoluteNumber < 0.01) {
    options.maximumFractionDigits = 4;
  }

  if (absoluteNumber >= compactFormatAt) {
    options.notation = 'compact';
  }

  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.formatToParts(amountAsNumber);
}

export type PpFiatAmount = {
  amount: TpAmount;
  approx?: boolean;
  currency: TpFiatCurrency | undefined;
  dataQa?: string;
  fallback?: React.ReactNode;
  fallbackCheck?: (amount: TpAmount) => boolean;
  locale?: string;
  roundDown?: boolean;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
  strikeThrough?: boolean;
  visible?: boolean;
};

export function FiatAmount({
  amount,
  approx = false,
  currency,
  dataQa = 'fiat-amount',
  fallback,
  fallbackCheck,
  locale,
  roundDown,
  signDisplay,
  strikeThrough,
  visible = true,
}: PpFiatAmount): React.ReactElement | null {
  const visibility = visible ? 'visible' : 'hidden';
  const textDecoration = strikeThrough ? 'line-through' : 'none';
  const amountAsParts = getAmountAsParts({
    amount,
    currency,
    locale,
    roundDown,
    signDisplay,
  });

  return (
    <span css={{ textDecoration, visibility }} data-qa={dataQa}>
      <TextOrSkeleton fallback={fallback} pretext={approx && '≈ '}>
        {fallbackCheck && fallbackCheck(amount)
          ? ''
          : amountAsParts && renderAmountAsParts({ amountAsParts, isCrypto: false })}
      </TextOrSkeleton>
    </span>
  );
}
