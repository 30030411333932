/* eslint-disable react/no-multi-comp */
import { Fragment, type ReactElement } from 'react';
import type { SkeletonProps } from '@mui/material';
import { Skeleton } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { List } from './List';

type SkeletonListProps = PpWC & {
  disablePadding?: boolean;
  rows: number;
};

export function SkeletonText(props: SkeletonProps): ReactElement {
  return (
    <Skeleton sx={{ borderRadius: (theme) => theme.borderRadius.sm }} variant="text" {...props} />
  );
}

export function SkeletonCircular(props: SkeletonProps): ReactElement {
  return <Skeleton variant="circular" {...props} />;
}

export function SkeletonRectangular(props: SkeletonProps): ReactElement {
  return (
    <Skeleton
      sx={{ borderRadius: (theme) => theme.borderRadius.sm }}
      variant="rectangular"
      {...props}
    />
  );
}

export function SkeletonList({
  children,
  disablePadding = false,
  rows,
}: SkeletonListProps): ReactElement {
  return (
    <List disablePadding={disablePadding} spacing={1}>
      {Array.from({ length: rows }).map((_, index) => (
        <Fragment key={`skeleton-row-${index + 1}`}>{children}</Fragment>
      ))}
    </List>
  );
}
