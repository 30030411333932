//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgClose } from '../icons/Close';

export function CloseIcon(props: Omit<SvgIconProps, 'children' | 'component'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgClose} data-qa="CloseIcon">
      <svg />
    </SvgIcon>
  );
}
