import type { TpAuthStatus } from './types';

type TpAuthNeeds = Array<Array<TpAuthStatus> | TpAuthStatus>;

type PpCheckAuthStatus = {
  has: TpAuthStatus;
  needs: TpAuthNeeds | undefined;
};
export function checkAuthStatus({ has, needs }: PpCheckAuthStatus): boolean {
  if (!needs) {
    return true;
  }
  return needs.every((need) => {
    // nested array is an OR condition
    if (Array.isArray(need)) {
      return need.some((n) => n === has);
    }
    // this is an AND condition
    return need === has;
  });
}
