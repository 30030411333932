import type { BooleanMap, FeatureRestrictedCountryRegionMap } from '@noah-labs/shared-schema-gql';

type TpConfig = {
  analytics: { cdnUrl: string };
  commitHash: string;
  isProd: boolean;
  kycExpirationAlertDays: number;
  lnDomain: string;
  regions: {
    prohibitedCountries: BooleanMap;
    restrictedRegions: FeatureRestrictedCountryRegionMap;
  };
  segment: { writeKey: string };
  settings: {
    referralCodeParam: string;
  };
};

let config: TpConfig | undefined;

export function setConfig(cfg: TpConfig): void {
  config = cfg;
}

export function getConfig(): TpConfig {
  if (!config) {
    throw new Error(
      'config is not set, please call `setConfig()` at the start of your application',
    );
  }
  return config;
}
