import { Fragment, useState } from 'react';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronDownIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronDownIcon';
import type { PpInputField } from './InputField';
import type { TpSelectOption } from './SearchSelect';
import { CloseButton } from '../buttons/CloseButton';
import { LowDialog } from '../dialogs/LowDialog';
import { InputField } from './InputField';
import { SearchSelect } from './SearchSelect';

export type PpSelectDialog = Pick<
  PpInputField,
  'disabled' | 'focused' | 'fullWidth' | 'helperText' | 'label' | 'name' | 'size' | 'startSlot'
> & {
  dataQa?: string;
  isFetched: boolean;
  onChange: (value: TpSelectOption | null) => void;
  options: TpSelectOption[] | undefined;
  required?: boolean;
};

export function DialogSelectField({
  dataQa = '',
  disabled,
  focused = false,
  fullWidth,
  helperText,
  isFetched,
  label,
  name,
  onChange,
  options,
  required,
  ...props
}: PpSelectDialog): React.ReactElement {
  const [open, setOpen] = useState(focused);
  const theme = useTheme();
  return (
    <Fragment>
      <InputField
        dataQa={name}
        endSlot={<ChevronDownIcon sx={{ color: theme.palette.grayscale[400] }} />}
        focused={focused}
        fullWidth={fullWidth}
        helperText={helperText}
        label={label}
        name={name}
        required={required}
        slotProps={{
          htmlInput: {
            readOnly: true,
          },
        }}
        onClick={(): void => {
          if (disabled) {
            return;
          }
          setOpen(true);
        }}
        {...props}
      />
      <LowDialog fullWidth keepMounted tallScreen data-qa={`${dataQa}-select-dialog`} open={open}>
        <CloseButton onClick={(): void => setOpen(false)} />
        <Container maxWidth="sm" sx={{ height: '100%', overflow: 'auto' }}>
          <SearchSelect
            id={name}
            loading={!isFetched}
            options={options}
            onChange={(value): void => {
              onChange(value);
            }}
            onClose={(): void => setOpen(false)}
          />
        </Container>
      </LowDialog>
    </Fragment>
  );
}
