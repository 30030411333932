export const pemHeader = '-----BEGIN PUBLIC KEY-----';
export const pemFooter = '-----END PUBLIC KEY-----';
/**
 * Hashes a byte array with SHA-256
 */
export async function digestSha256(buf: ArrayBufferLike): Promise<ArrayBuffer> {
  return globalThis.crypto.subtle.digest('SHA-256', buf);
}

/**
 * Encodes a string into a byte array
 */
export function strToBuffer(str: string, encoding?: BufferEncoding): ArrayBufferLike {
  return Buffer.from(str, encoding);
}

/**
 * Converts a byte array into a hex string.
 */
export function bufferToHex(buffer: ArrayBufferLike): string {
  return Buffer.from(buffer).toString('hex');
}

/**
 * Converts a byte array into a base64 string.
 */
export function bufferToBase64(buffer: ArrayBufferLike | Buffer): string {
  return Buffer.from(buffer).toString('base64');
}

/**
 * Converts a number to a fixed length byte array. The number is stored in little endian.
 * Example: number 1234 and length 6 is stored as [4, 3, 2, 1, 0, 0]
 */
export function numberToFixedLenBuffer(n: number, len: number): ArrayBuffer {
  const buffer = new ArrayBuffer(len);
  for (let i = 0; i < len; i += 1) {
    const digit = n % 10;
    // eslint-disable-next-line no-param-reassign
    n = Math.floor(n / 10);
    new DataView(buffer).setUint8(i, digit);
  }
  return buffer;
}

/*
 * Generates an AES-GCM key
 */
export async function generateAESKey(): Promise<CryptoKey> {
  return globalThis.crypto.subtle.generateKey({ length: 256, name: 'AES-GCM' }, true, [
    'encrypt',
    'decrypt',
  ]);
}

/**
 * Generates a random 32 byte salt
 */
export async function generateSalt(): Promise<ArrayBuffer> {
  return globalThis.crypto.getRandomValues(new Uint8Array(32));
}

/**
 * Takes an input, a salt and a number of iterations and derives a key using the PBKDF2 algorithm
 * The key can be used to symetrically encrypt/decrypt data, e.g. with AES-GCM
 */
export async function derivePbkdf2Key(
  input: ArrayBufferLike,
  salt: ArrayBufferLike,
  iterations: number,
): Promise<CryptoKey> {
  const keyMaterial = await globalThis.crypto.subtle.importKey('raw', input, 'PBKDF2', false, [
    'deriveBits',
    'deriveKey',
  ]);
  return globalThis.crypto.subtle.deriveKey(
    {
      hash: 'SHA-256',
      iterations,
      name: 'PBKDF2',
      salt,
    },
    keyMaterial,
    { length: 256, name: 'AES-GCM' },
    true,
    ['encrypt', 'decrypt'],
  );
}

/**
 * Imports a pem encoded RSA public key
 * Key has the following scopes: [encrypt]
 */
export async function importPublicKey(pem: string): Promise<CryptoKey> {
  const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length);
  const binaryDerString = globalThis.atob(pemContents);
  const binaryDer = Uint8Array.from(binaryDerString, (c) => c.charCodeAt(0));

  return globalThis.crypto.subtle.importKey(
    'spki',
    binaryDer.buffer,
    {
      hash: { name: 'SHA-256' },
      name: 'RSA-OAEP',
    },
    true,
    ['encrypt'],
  );
}

/**
 * Encrypts data with an RSA public key
 */
export async function encryptWithRsa(key: CryptoKey, data: ArrayBufferLike): Promise<ArrayBuffer> {
  return globalThis.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    key,
    data,
  );
}

// Validation function specific for the key with try/catch and signing algorithm parameter
async function validateKeyWithAlgorithm(
  binaryDerBuffer: ArrayBuffer,
  algorithm: { name: string; namedCurve: string },
): Promise<boolean> {
  try {
    await globalThis.crypto.subtle.importKey('spki', binaryDerBuffer, algorithm, true, ['verify']);
    return true; // Key import successful
  } catch {
    return false; // Key import failed
  }
}

// Master function that handles the overall key validation logic
export async function validatePublicKey(pem: string): Promise<boolean> {
  try {
    const trimmedPem = pem.trim();
    const pemContents = trimmedPem.substring(
      pemHeader.length,
      trimmedPem.length - pemFooter.length,
    );
    const binaryDerString = globalThis.atob(pemContents);
    const binaryDer = Uint8Array.from(binaryDerString, (c) => c.charCodeAt(0));

    // First try to validate with ES384
    if (await validateKeyWithAlgorithm(binaryDer.buffer, { name: 'ECDSA', namedCurve: 'P-384' })) {
      return true;
    }
    // If ES384 fails, then try with ES256
    return await validateKeyWithAlgorithm(binaryDer.buffer, { name: 'ECDSA', namedCurve: 'P-256' });
  } catch {
    return false;
  }
}

export function extractKey(pem: string): string | undefined {
  const trimmedPem = pem.trim();
  if (trimmedPem === '') {
    return undefined;
  }
  if (!trimmedPem.startsWith(pemHeader) || !trimmedPem.endsWith(pemFooter)) {
    throw new Error('Invalid PEM key');
  }
  const pemContents = trimmedPem.substring(pemHeader.length, trimmedPem.length - pemFooter.length);
  return pemContents.trim();
}
