import type { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { InfoTooltip } from '../buttons/InfoTooltip';

type PpBalanceLabel = {
  label: string;
  onLabelClick?: () => void;
};

export function BalanceLabel({ label, onLabelClick }: PpBalanceLabel): ReactElement {
  if (onLabelClick) {
    return (
      <InfoTooltip alignSelf="center" color="text.light" onClick={onLabelClick}>
        {label}
      </InfoTooltip>
    );
  }

  return (
    <Typography gutterBottom color="text.light" variant="paragraphBodyM">
      {label}
    </Typography>
  );
}
