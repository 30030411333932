import WebFont from 'webfontloader';
import { RootProvidersBrowser } from './src/RootProviders.tsx';

export const wrapRootElement = RootProvidersBrowser;

export function onInitialClientRender() {
  WebFont.load({
    custom: {
      families: ['GT America Standard'],
    },
  });
}
