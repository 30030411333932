import type {
  AppConfigManualRamp,
  AppConfigNetworkFee,
  BooleanMap,
  FeatureRestrictedCountryRegionMap,
} from '@noah-labs/shared-schema-gql';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export type TpConfig = {
  appStage: string;
  cko: {
    feeMinimumFiatAmount: string;
    feePercentage: string;
    googlePayGateway: string;
    googlePayMerchantId: string;
    minimumFiatAmount: string;
    publicKey: string;
    slippagePercentage: string;
  };
  enableLoaderAnimations: boolean;
  isProd: boolean;
  manualRamp: AppConfigManualRamp;
  networkFee: AppConfigNetworkFee;
  novu: {
    applicationId: string;
  };
  regions: {
    prohibitedCountries: BooleanMap;
    restrictedRegions: FeatureRestrictedCountryRegionMap;
  };
};

dayjs.extend(duration);

let config: TpConfig | undefined;

export function setConfig(cfg: TpConfig): void {
  config = cfg;
}

export function getConfig(): TpConfig {
  if (!config) {
    throw new Error(
      'config for feature-wallet is not set, please call `setConfig()` at the start of your application',
    );
  }
  return config;
}
