import { css } from '@emotion/react';
import { Avatar } from '@mui/material';
import type { AvatarProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ContactIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ContactIcon';

type PpAvatarWithTransactionStatus = {
  AvatarSlot?: React.ReactElement;
  BadgeSlot?: React.ReactElement;
  size?: number;
};

export function AvatarWithBadge({
  AvatarSlot, // 24px
  BadgeSlot,
  size = 3,
  ...rest
}: AvatarProps & PpAvatarWithTransactionStatus): React.ReactElement {
  const theme = useTheme();

  const sz = theme.spacing(size);

  const styles = {
    avatar: css`
      height: ${sz};
      width: ${sz};
      background-color: initial;
      color: ${theme.palette.secondary.dark};
      overflow: visible;
      position: relative;
      .avatar {
        height: 100%;
        width: 100%;
      }
    `,
    badge: css`
      position: absolute;
      bottom: 0px;
      right: 0px;
      z-index: 10;
    `,
  };

  return (
    <Avatar css={styles.avatar} {...rest}>
      {AvatarSlot || <ContactIcon className="avatar" />}
      {BadgeSlot && <div css={styles.badge}>{BadgeSlot}</div>}
    </Avatar>
  );
}
