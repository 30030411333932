import { css } from '@emotion/react';
import { Box } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';

export type PpAccentedDivider = {
  accentColor?: string;
  orientation?: 'horizontal' | 'vertical';
  sx?: SxProps<Theme>;
};

export function AccentedDivider({
  accentColor,
  orientation = 'horizontal',
  sx,
}: PpAccentedDivider): React.ReactElement {
  const theme = useTheme();
  const color = accentColor || theme.palette.brand.light;
  const styles = {
    divider: css`
      display: block;
      width: ${orientation === 'horizontal' ? '100%' : '1px'};
      height: ${orientation === 'horizontal' ? '1px' : '1.25em'};
      background: linear-gradient(
        ${orientation === 'horizontal' ? '90deg' : '0deg'},
        ${alpha(color, 0)} 0%,
        ${color} 50%,
        ${alpha(color, 0)} 100%
      );
    `,
  };

  return <Box css={styles.divider} sx={sx} />;
}
