import type { FeatureFlag, KycVerification } from '@noah-labs/shared-schema-gql';
import { Feature } from '@noah-labs/shared-schema-gql';
import { TpFeExtraFeature, type TpFeFeature, type TpFeFeatureFlag } from '../types';
import { getEnabledWithKYCFeature } from './getEnabledWithKYCFeature';

type PpGetUIFeatures = {
  FeatureFlags: FeatureFlag[] | null | undefined;
  KycVerification: KycVerification | null | undefined;
};

export function getUIFeatures({
  FeatureFlags,
  KycVerification,
}: PpGetUIFeatures): Map<TpFeFeature, TpFeFeatureFlag> {
  if (!FeatureFlags) {
    return new Map<TpFeFeature, TpFeFeatureFlag>();
  }

  const fm: Map<TpFeFeature, TpFeFeatureFlag> = new Map(
    FeatureFlags.map((feature) => [feature.Feature, feature]),
  );

  const accessBuy = getEnabledWithKYCFeature({
    feature: Feature.Buy,
    fm: fm as Map<Feature, FeatureFlag>,
    kycStatus: KycVerification?.ApprovalStatus,
  });
  const accessSell = getEnabledWithKYCFeature({
    feature: Feature.Sell,
    fm: fm as Map<Feature, FeatureFlag>,
    kycStatus: KycVerification?.ApprovalStatus,
  });

  fm.set(TpFeExtraFeature.BuyRoutes, {
    ...accessBuy,
    Feature: TpFeExtraFeature.BuyRoutes,
  });
  fm.set(TpFeExtraFeature.SellRoutes, {
    ...accessSell,
    Feature: TpFeExtraFeature.SellRoutes,
  });

  return fm;
}
