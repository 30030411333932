import type { ReactElement } from 'react';
import type { LinkProps } from '@mui/material';
import { Link } from '@mui/material';
import { getInteractiveProps } from '../links/getInteractiveProps';

/**
 * if link is being used as a button, we must provide a variant (not inherit) else it will not be styled correctly
 * see: https://github.com/mui/material-ui/issues/32942
 * and https://github.com/mui/material-ui/pull/38123
 */
export function InlineLinkOrButton({
  href,
  onClick,
  sx,
  variant = 'inherit',
  ...rest
}: LinkProps & { disabled?: boolean }): ReactElement {
  const interactiveProps = getInteractiveProps({ href, onClick });
  let ssx = sx;

  // link buttons do not have fontFamily set by default
  // we only override this in the case the variant is not inherit
  // so that the correct variant font-family will be used
  if (interactiveProps.component === 'button' && variant !== 'inherit') {
    ssx = {
      fontFamily: 'inherit',
      ...sx,
    };
  }

  // if variant is inherit, we need to set the font props explicitly
  // it can't be done in the theme because the inherit class is not applied as expected
  if (variant === 'inherit') {
    ssx = {
      font: 'inherit',
      letterSpacing: 'inherit',
      lineHeight: 'inherit',
      ...sx,
    };
  }

  return (
    <Link
      {...interactiveProps}
      href={href}
      sx={ssx}
      variant={variant}
      onClick={onClick}
      {...rest}
    />
  );
}
