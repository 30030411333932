import { TpCheckoutPaymentStatus, TpCheckoutPayoutStatus } from '@noah-labs/fe-shared-util-shared';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { getRouteSegments } from './getRouteSegments';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createWalletRoutes() {
  // URL FORMAT
  // /wallet/<Action>/<CurrencyCode>/<AccountType>/<Network>/<Step>
  // Action = Buy, Sell, Receive, Send

  /**
   * Ensure that only the routes we specify in our configs are available,
   * so that we know that if the route renders, it must be one of enums
   */
  const paymentStatuses = Object.keys(TpCheckoutPaymentStatus).join('|').toLowerCase();
  const payoutStatuses = Object.keys(TpCheckoutPayoutStatus).join('|').toLowerCase();

  const { accountType, currencyCode, network } = getRouteSegments();
  const fiatCurrencyCodes = [FiatCurrencyCode.USD, FiatCurrencyCode.EUR, FiatCurrencyCode.GBP]
    .join('|')
    .toLowerCase();

  const fiatCurrencyCode = `:fiatCurrencyCode(${fiatCurrencyCodes})`;

  return {
    /**
     * If walletRoutes change, be sure to update any links in the website
     */
    account: {
      base: { path: `/wallet/${currencyCode}/${accountType}` },
      transaction: { path: `/wallet/${currencyCode}/${accountType}/transactions/:id` },
    },
    base: { path: '/wallet', title: 'Home' },
    buy: {
      /**
       * If buy routes change, be sure to update `libs/cloud/shared/constants/src/getDomains.ts` to have the correct url for 3DS redirects
       */
      base: { path: `/wallet/buy`, title: 'Buy' },
      channelPaymentMethod: {
        path: `/wallet/buy/${currencyCode}/${accountType}/channel`,
        title: 'Select Payment Method',
      },
      complete: {
        path: `/wallet/buy/${currencyCode}/${accountType}/complete/:paymentStatus(${paymentStatuses})`,
        title: 'Transaction Complete',
      },
      confirm: {
        path: `/wallet/buy/${currencyCode}/${accountType}/confirm`,
        title: 'Buy Confirmation',
      },
      enterAmount: { path: `/wallet/buy/${currencyCode}/${accountType}/enterAmount` },
      payment: {
        path: `/wallet/buy/${currencyCode}/${accountType}/payment`,
        title: 'Card Details',
      },
      selectCurrency: {
        path: `/wallet/buy/${currencyCode}/${accountType}/currency`,
        title: 'Select Currency',
      },
      transferDetails: {
        path: `/wallet/buy/${currencyCode}/${accountType}/transferDetails`,
        title: 'Complete your transfer',
      },
      virtualAccount: {
        path: `/wallet/buy/${currencyCode}/${accountType}/virtualAccount`,
        title: 'Virtual Account',
      },
      virtualAccountSuccess: {
        path: `/wallet/buy/${currencyCode}/${accountType}/virtualAccountSuccess`,
        title: 'Virtual Account Ready',
      },
      withParams: { path: `/wallet/buy/${currencyCode}/${accountType}` },
    },
    payout: {
      base: { path: '/wallet/payout' },
      customerId: {
        path: '/wallet/payout/customer-id',
        title: 'Recipient',
      },
      enterAmount: {
        path: `/wallet/payout/${currencyCode}/${accountType}/${fiatCurrencyCode}/enter-amount`,
        title: 'Enter Amount',
      },
      orderSummary: {
        path: `/wallet/payout/${currencyCode}/${accountType}/${fiatCurrencyCode}/order-summary`,
        title: 'Transfer Order Summary',
      },
      paymentMethods: {
        path: '/wallet/payout/payment-method',
        title: 'Select Payout Method',
      },
      selectAccount: {
        path: '/wallet/payout/select-account',
        title: 'Source Account',
      },
      selectCurrency: {
        path: `/wallet/payout/${currencyCode}/${accountType}/select-currency`,
        title: 'Recipient Currency',
      },
      transferConfirmation: {
        path: `/wallet/payout/${currencyCode}/${accountType}/${fiatCurrencyCode}/transfer-confirmation`,
        title: 'Transfer Requested',
      },
    },
    receive: {
      base: { path: '/wallet/receive', title: 'Select Account' }, // select account
      lightning: {
        enterAmount: {
          path: `/wallet/receive/${currencyCode}/${accountType}/lightning`,
          title: 'Enter Request Amount',
        },
        viewInvoice: {
          path: `/wallet/receive/${currencyCode}/${accountType}/lightning/view-invoice`,
          title: 'Invoice',
        },
      },
      network: {
        path: `/wallet/receive/${currencyCode}/${accountType}/network`,
        title: 'Select Network',
      },
      viewAddress: {
        path: `/wallet/receive/${currencyCode}/${accountType}/${network}/address`,
        title: 'Receive',
      },
    },
    sell: {
      base: { path: `/wallet/sell/${currencyCode}/${accountType}`, title: 'Sell' }, // payoutMethod
      complete: {
        path: `/wallet/sell/${currencyCode}/${accountType}/complete/:payoutStatus(${payoutStatuses})`,
        title: 'Transaction Complete',
      },
      confirm: {
        path: `/wallet/sell/${currencyCode}/${accountType}/confirm`,
        title: 'Sell Confirmation',
      },
      enterAccountDetails: {
        path: `/wallet/sell/${currencyCode}/${accountType}/payment/bank`,
        title: 'Account details',
      },
      enterAmount: {
        path: `/wallet/sell/${currencyCode}/${accountType}/enter-amount`,
        title: 'Sell',
      },
      enterPaymentInfo: { path: `/wallet/sell/${currencyCode}/${accountType}/payment/card` },
      payoutMethod: { path: `/wallet/sell/${currencyCode}/${accountType}`, title: 'Card Details' },
    },
    send: {
      address: {
        base: {
          path: `/wallet/send/${currencyCode}/${accountType}/${network}/address`,
          title: 'Enter Address',
        },
        scan: {
          path: `/wallet/send/${currencyCode}/${accountType}/${network}/address/scan`,
          title: 'Scan Address',
        },
      },
      base: { path: '/wallet/send', title: 'Select account' }, // select account
      complete: {
        path: `/wallet/send/${currencyCode}/${accountType}/${network}/complete`,
        title: 'Processing Withdrawal',
      },
      confirm: {
        path: `/wallet/send/${currencyCode}/${accountType}/${network}/confirm`,
        title: 'Confirm Transaction',
      },
      enterAmount: {
        path: `/wallet/send/${currencyCode}/${accountType}/${network}/enter-amount`,
        title: 'Enter Send Amount',
      },
      lightning: {
        base: { path: `/wallet/send/${currencyCode}/${accountType}/lightning/` },
        complete: {
          path: `/wallet/send/${currencyCode}/${accountType}/lightning/complete`,
          title: 'Payment Processing',
        },
        confirm: {
          path: `/wallet/send/${currencyCode}/${accountType}/lightning/confirm`,
          title: 'Confirm Transaction',
        },
        enterAmount: {
          path: `/wallet/send/${currencyCode}/${accountType}/lightning/enter-amount`,
          title: 'Enter Send Amount',
        },
        invoiceExpired: {
          path: `/wallet/send/${currencyCode}/${accountType}/lightning/invoice-expired`,
          title: 'Invoice Expired',
        },
        request: {
          path: `/wallet/send/${currencyCode}/${accountType}/lightning/request/:source/:encodedSendParams`,
        },
      },
      network: {
        path: `/wallet/send/${currencyCode}/${accountType}/network`,
        title: 'Select network',
      },
      withParams: { path: `/wallet/send/${currencyCode}/${accountType}/${network}` },
    },
    transactions: {
      transaction: { path: '/wallet/transactions/:id', title: 'Transaction Detail' },
    },
  };
}

let walletRoutesCached: ReturnType<typeof createWalletRoutes> | undefined;

export function walletRoutes(): ReturnType<typeof createWalletRoutes> {
  if (!walletRoutesCached) {
    walletRoutesCached = createWalletRoutes();
  }
  return walletRoutesCached;
}
