import type { ReactElement } from 'react';
import type { TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { AvatarIconFromSvg } from '../mui/images/Avatar';
import { List } from '../mui/lists/List';
import {
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '../mui/lists/ListItem';

type PpChooseCurrencyList = {
  currencies: TpFiatCurrencyUI[] | undefined;
  onNext: (fiatCurrency: TpFiatCurrencyUI) => void;
};

export function ChooseCurrencyList({ currencies, onNext }: PpChooseCurrencyList): ReactElement {
  return (
    <List disablePadding spacing={1}>
      {currencies?.map((currency) => (
        <ListItem key={currency.code} data-qa={`currency-${currency.code}`}>
          <ListItemButton onClick={(): void => onNext(currency)}>
            <ListItemCard>
              <ListItemContent>
                <ListItemIcon>
                  <AvatarIconFromSvg circularIcon inheritViewBox size={3.5} svg={currency.svg} />
                </ListItemIcon>
                <ListItemStartContent>
                  <ListItemPrimaryText>{currency.label}</ListItemPrimaryText>
                  <ListItemSecondaryText>
                    {currency.code} ({currency.symbol})
                  </ListItemSecondaryText>
                </ListItemStartContent>
              </ListItemContent>
            </ListItemCard>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
