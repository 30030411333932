//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgPending } from '../icons/Pending';

export function PendingIcon(
  props: Omit<SvgIconProps, 'children' | 'component'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgPending} data-qa="PendingIcon">
      <svg />
    </SvgIcon>
  );
}
