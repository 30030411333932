export type TpConfig = {
  appkit: {
    projectId: string;
  };
};

let config: TpConfig | undefined;

export function setConfig(cfg: TpConfig): void {
  config = cfg;
}

export function getConfig(): TpConfig {
  if (!config) {
    throw new Error(
      'config for data-access-appkit is not set, please call `setConfig()` at the start of your application',
    );
  }
  return config;
}
