import type {
  AppConfigManualRamp,
  BooleanMap,
  FiatPaymentCardScheme,
} from '@noah-labs/shared-schema-gql';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

type TpConfig = {
  cko: {
    cardHolderNameMatchThreshold: string;
    feePercentage: string;
    minimumFiatAmount: string;
    publicKey: string;
    supportedFiatPaymentCards: FiatPaymentCardScheme[];
  };
  enableLoaderAnimations: boolean;
  isProd: boolean;
  manualRamp: AppConfigManualRamp;
  regions: {
    prohibitedCountries: BooleanMap;
  };
};

dayjs.extend(duration);

let config: TpConfig | undefined;

export function setConfig(cfg: TpConfig): void {
  config = cfg;
}

export function getConfig(): TpConfig {
  if (!config) {
    throw new Error(
      'config is not set, please call `setConfig()` at the start of your application',
    );
  }
  return config;
}
