import europeanUnion from '@noah-labs/fe-shared-ui-assets/svg/icons/european-union.svg';
import unitedKingdom from '@noah-labs/fe-shared-ui-assets/svg/icons/united-kingdom.svg';
import unitedStates from '@noah-labs/fe-shared-ui-assets/svg/icons/united-states.svg';
import { currencyToPrimaryCountry, fiatCurrencies } from '@noah-labs/shared-currencies';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { FiatAmount } from '@noah-labs/shared-schema-gql';
import countryFlag from 'country-flag-icons/react/1x1';
import type { TpFiatCurrencyUI } from './types';

export const fiatCurrenciesUi = Object.entries(fiatCurrencies).reduce(
  (acc, [key, value]) => {
    const countryCode = currencyToPrimaryCountry[value.code];
    const valueWithIcons: TpFiatCurrencyUI = {
      ...value,
      svg: countryCode
        ? (countryFlag[countryCode as keyof typeof countryFlag] as TpReactSvg)
        : null,
    };

    acc[key as FiatCurrencyCode] = valueWithIcons;
    return acc;
  },
  {} as Record<FiatCurrencyCode, TpFiatCurrencyUI>,
);

export function fiatCurrencyFromString(code: string | undefined): TpFiatCurrencyUI | undefined {
  if (typeof code !== 'string') {
    return undefined;
  }
  const codeUpper = code.toUpperCase();
  // Happy to use 'as' here because it will return undefined if it doesn't exist
  return fiatCurrenciesUi[codeUpper as FiatCurrencyCode];
}

export function fiatCurrencyFromCode(code: FiatCurrencyCode): TpFiatCurrencyUI {
  return fiatCurrenciesUi[code];
}

/**
 * Returns the fiat amount for the given currency code, or the first one if it doesn't exist
 */
export function fiatAmountForCurrency(
  amounts: FiatAmount[],
  currencyCode: FiatCurrencyCode | undefined,
): FiatAmount {
  const amount = amounts.find((am) => am.FiatCurrency === currencyCode);
  return amount || amounts[0];
}

// TODO (cs): should really be coming from the 'master' countries / currencies list
// https://linear.app/noah-hq/issue/FE-1559/get-list-of-available-payment-currencies-from-our-channels-api-in
export const fiatCurrencyItems = [
  {
    label: 'US Dollar',
    secondaryLabel: `${FiatCurrencyCode.USD} ($)`,
    svg: unitedStates,
    value: FiatCurrencyCode.USD,
  },
  {
    label: 'Great British Pound',
    secondaryLabel: `${FiatCurrencyCode.GBP} (£)`,
    svg: unitedKingdom,
    value: FiatCurrencyCode.GBP,
  },
  {
    label: 'Euro',
    secondaryLabel: `${FiatCurrencyCode.EUR} (€)`,
    svg: europeanUnion,
    value: FiatCurrencyCode.EUR,
  },
];
