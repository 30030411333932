import { useMemo } from 'react';
import { useStateMachine } from 'little-state-machine';

type ReadOnlyURLSearchParams = URLSearchParams & {
  append: never;
  delete: never;
  set: never;
  sort: never;
};

export function useSearchParams(): ReadOnlyURLSearchParams | undefined {
  const {
    state: { search },
  } = useStateMachine();
  const sp = useMemo(() => {
    /**
     * sometimes search can be an empty string, which causes `ss` to become an object,
     * but without the URLSearchParams methods, hence accessing causes a crash (only happened in e2e tests)
     */
    if (!search) {
      return undefined;
    }
    const ss = new URLSearchParams(search) as ReadOnlyURLSearchParams;
    return ss;
  }, [search]);
  return sp;
}
