//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgSquareTick } from '../icons/SquareTick';

export function SquareTickIcon(
  props: Omit<SvgIconProps, 'children' | 'component'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgSquareTick} data-qa="SquareTickIcon">
      <svg />
    </SvgIcon>
  );
}
