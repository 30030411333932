import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';

export type PpAmountParts = {
  amountAsParts: Intl.NumberFormatPart[];
  colorCurrency?: string;
  colorFraction?: string;
  colorMinus?: string;
  colorPlus?: string;
  isCrypto: boolean;
};
/**
 * renderAmountAsParts doesn't return a jsx component because we want it to be able
 * to be null for showing text as skeletons
 */
export function renderAmountAsParts({
  amountAsParts,
  colorCurrency = 'inherit',
  colorFraction = 'inherit',
  colorMinus = 'inherit',
  colorPlus = 'inherit',
  isCrypto,
}: PpAmountParts): React.ReactElement[] {
  if (!Array.isArray(amountAsParts)) {
    return [];
  }
  const spacing = '0.25ch';
  const symbolSpacing = isCrypto ? spacing : undefined;

  return amountAsParts.map(({ type, value }, i) => {
    // Use index in key otherwise can be the same and results in the weird extra commas issue
    const key = `${type}${value}${i}`;
    const commonProps: TypographyProps = {
      component: 'span',
      variant: 'inherit',
    };

    switch (type) {
      case 'currency':
        // the currency symbol
        return (
          <Typography
            key={key}
            {...commonProps}
            sx={{
              color: colorCurrency,
              marginLeft: i === amountAsParts.length - 1 ? symbolSpacing : undefined,
              marginRight: i === 0 ? symbolSpacing : undefined,
            }}
          >
            {value}
          </Typography>
        );
      case 'decimal':
        // The decimal point
        return (
          <Typography key={key} {...commonProps} sx={{ color: colorFraction }}>
            {value}
          </Typography>
        );
      case 'fraction': {
        // number after the decimal point
        return (
          <Typography key={key} {...commonProps} sx={{ color: colorFraction }}>
            {value}
          </Typography>
        );
      }
      case 'minusSign':
        return (
          <Typography key={key} {...commonProps} sx={{ color: colorMinus }}>
            {value}
          </Typography>
        );
      case 'plusSign':
        return (
          <Typography key={key} {...commonProps} sx={{ color: colorPlus }}>
            {value}
          </Typography>
        );
      default:
        // integer and groups etc.
        return (
          <Typography key={key} {...commonProps}>
            {value}
          </Typography>
        );
    }
  });
}
