//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgPlusCircleThick } from '../icons/PlusCircleThick';

export function PlusCircleThickIcon(
  props: Omit<SvgIconProps, 'children' | 'component'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgPlusCircleThick} data-qa="PlusCircleThickIcon">
      <svg />
    </SvgIcon>
  );
}
