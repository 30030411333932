import type { ReactNode } from 'react';
import type { LnurlError as TpLnurlError } from '@noah-labs/shared-schema-gql';
import type { TpPushAlert } from './usePushAlert';

/**
 * Errors
 */
export function LnurlErrorAlert({ Domain, Reason }: TpLnurlError): TpPushAlert {
  return {
    autoHideDuration: 6000,
    dismissable: true,
    key: 'LnurlError',
    message: `The ${Domain} lightning service responded with: ${Reason}`,
    preventDuplicate: true,
    severity: 'error',
  };
}

export function AlertError(key: string, error: ReactNode): TpPushAlert {
  return {
    autoHideDuration: 3000,
    key,
    message: error,
    preventDuplicate: true,
    severity: 'error',
  };
}

export const AddressCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'addressCopied',
  message: 'Address copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};

export const InvalidAddressErrorAlert: TpPushAlert = {
  autoHideDuration: 6000,
  dismissable: true,
  key: 'invalidAddress',
  message:
    'Oops! The address you entered is invalid. Please double-check for any errors and try again.',
  preventDuplicate: true,
  severity: 'error',
};

export const AccountNameCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'accountNameCopied',
  message: 'Account name copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};

export const AccountNumberCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'accountNumberCopied',
  message: 'Account number copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};

export const RoutingNumberCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'routingNumberCopied',
  message: 'Routing number copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};

export const BankNameCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'bankNameCopied',
  message: 'Bank Name Copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};

export const BankAddressCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'bankAddressCopied',
  message: 'Bank Address Copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};

export const PublicKeyCopiedAlert: TpPushAlert = {
  autoHideDuration: 2000,
  dismissable: true,
  key: 'publicKeyCopied',
  message: 'Public key copied to clipboard.',
  preventDuplicate: true,
  severity: 'success',
};
